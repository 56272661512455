/* FONTS */

@font-link {
  font-family: "Ubuntu", sans-serif;
}
/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  width: 100%;
  overflow-x: hidden;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #120130 !important;
  color: #fff !important;
  font-family: "Ubuntu", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-transform: uppercase;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 3;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  height: 10vh;
  background-color: #1b02469f;
}

nav.navbar a.navbar-brand {
  width: 40%;
}
.navbar-expand-lg {
  align-items: end !important;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  font-size: smaller;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  padding-bottom: 16px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover {
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
  color: #a8fe39 !important;
}
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  color: #740a79;
}

span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
  padding-inline: 16px;
}
.social-icon a {
  width: 30px;
  height: 30px;
  display: inline-flex;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  padding-inline: 16px;
}

.social-icon a img::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a img:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 42px;
  height: 42px;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1.5px solid #fff;
  border-radius: 14px;
  padding: 10px 20px;
  font-size: 16px;
  text-wrap: nowrap;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  border: 1.5px solid #a8fe39;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #a8fe39;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: #02c9f2;
  color: #02c9f2;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  color: #02c9f2;
  position: relative;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #02c9f2;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
.navbar-toggler-icon {
  width: 20px;
  height: 10px;
  background-color: transparent;
}

/************ Banner Css ************/

.banner {
  margin-top: 0;
  padding: 320px 0 100px 0;
  width: 100%;
  background-image: url("./assets/img/bg-2.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .container {
  width: 90vw;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  display: block;
  min-height: 195px;
}
.banner p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button:hover {
  color: #a8fe39;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 15px;
  transform: scale(110%);
  color: #ff83cd;
}
.banner img {
  -webkit-animation: updown 3s linear infinite;
  animation: updown 3s linear infinite;
}
span.wrap {
  color: #02c9f2;
  text-transform: uppercase;
}
.headerImg {
  width: 120%;
}

@-webkit-keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/

.skill {
  padding: 0 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.skill-bx {
  background: #120130;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
  font-size: 45px;
  font-weight: 700;
}
.skill p {
  font-size: large;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill span {
  display: block;
  padding: 20px 0;
  color: #f30092;
  text-transform: lowercase;
  font-size: smaller;
}
.skill-slider .item {
  width: 50%;

  cursor: -webkit-grab;
  cursor: grab;
}
.react-multiple-carousel-list {
  padding: 40px 0;
  height: 50vh;
}
.react-multiple-carousel__arrow {
  opacity: 0.7;
}
.react-multiple-carousel-item {
  max-width: 200px !important;
}
.progress {
  width: 200px;
}
.prog-bar h5 {
  margin-top: 1em;
}

.prog-bar {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

/************ Projects Css ************/

.project {
  padding: 80px 0;

  position: relative;
  background-color: black;
}
.project .container {
  width: 90vw;
}
.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.project p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project p a {
  text-decoration: none;
  color: #f30092;
  font-family: monospace;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 50%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  top: 0;
  left: 0;
  z-index: -3;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
}
.nav-link#projects-tabs-tab-first {
}
.project p a {
  text-decoration: underline;
}
.project .nav.nav-pills .nav-link {
  font-size: small;
  text-wrap: balance;
  line-height: 1.4;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 24px;
  min-height: 150px !important;
  height: 180px !important ;
}
.proj-imgbx img {
  min-height: 150px !important;
  max-height: 180px !important ;
  image-rendering: optimizeQuality;
  -o-object-fit: cover;
  -o-object-fit: cover;
  object-fit: cover;
  object-position: center;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.7px;
  line-height: 1.1em;
}
.btn-page {
  font-weight: 500;
  color: #fff;
  border-radius: 14px;
  padding: 5px 10px;
  font-size: 16px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.btn-page::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.btn-page:hover {
  color: #02c9f2;
  border: 1px solid #02c9f2;
}
.btn-page:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

.proj-txtx p {
  color: #02c9f2;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 1px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Contact Css ************/

.contact {
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  padding: 60px 0 200px 0;
  line-height: 1.5;
  width: 100%;
  filter: hue-rotate(313deg);
}
.contact .container {
  width: 90vw;
}
.contact p {
  font-size: 20px;
  margin: 30px 0;
}
.contact img {
  width: 100%;
  left: auto;
}
.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact-icon {
  width: 50px !important;
  height: 50px !important;
  display: flex;
  justify-content: space-evenly;
}
.contact-icon a {
  width: 50px !important;
  height: 50px !important;
}

.contact h4 {
  font-size: 30px;
}
.contact-span {
  color: #02c9f2;
}
.contact .btn {
  font-size: larger;
}
.contact .btn:hover {
  background-color: transparent;
}
form {
  position: relative;
  z-index: 2;
}
form button {
  position: absolute;
  right: 0;
}
label {
  font-size: larger;
}

/************ Footer Css ************/

.footer {
  width: 100%;
}
.footer .social-icon a img {
  width: 60px;
  height: 60px;
  padding: 16px;
}

.footer span {
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: rgb(96, 95, 95);
  text-decoration: none;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
.footer span a {
  text-decoration: none;
  color: #02c9f2;
}
@media screen and (min-width: 375px) {
  .navbar-brand {
    width: 30% !important;
  }
  .navbar-text {
    display: none !important;
  }
  .navbar-collapse {
    text-align: end !important;
  }
}
@media only screen and (width: 390px) and (height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .navbar-brand {
    width: 30% !important;
  }
  .navbar-text {
    display: none !important;
  }
  .navbar-collapse {
    text-align: end !important;
  }
}
@media screen and (min-width: 768px) {
  .navbar-collapse {
    text-align: end !important;
  }
}
